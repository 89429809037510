import { featuresService } from '../../../../../../App';
import { SportTypes } from '../../../../../../constants/sport.types';
import { getProjectSubdomain } from '../../../../../../global-helpers/global-url.helpers';
import { capitalize, loadHTMLText } from '../../../../../../global-helpers/global.helpers';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { store } from '../../../../../../store/store';
import { WidgetV2RefreshTimes } from '../../partials/refresh-time-input/helper';

const envSubdomain = getProjectSubdomain().replaceAll('.', '-');
const refreshTimeDisableValue = 'never';
const convertSportToFeatureType = (sport: string) => (sport === SportTypes.FOOTBALL ? FeatureTypes.FOOTBALL_V2 : sport);

const addWidgetsV2Script = (sport: string, widgetVersion: string) => {
	const script = document.createElement('script');
	script.src = `https://widgets${envSubdomain}.sportal365.com/${sport}.${widgetVersion}.js`;
	script.type = 'module';
	document.head.appendChild(script);
};

const getLatestWidgetsV2Version = async () => {
	const localStorageWidgetV2VersionKey = 'widgetV2Version';

	const data = await fetch(`https://widgets${envSubdomain}.sportal365.com/metadata.json`)
		.then((response) => response.json())
		.catch(() => null);

	if (!data) {
		return localStorage.getItem(localStorageWidgetV2VersionKey) || '5.4.0';
	}

	const responseValue = await data.widgets.latest_version;
	localStorage.setItem(localStorageWidgetV2VersionKey, responseValue);
	return responseValue;
};

export const addWidgetsV2Scripts = async () => {
	const widgetSports = [SportTypes.FOOTBALL, SportTypes.BASKETBALL, SportTypes.TENNIS];
	const widgetVersion = await getLatestWidgetsV2Version();
	widgetSports.forEach((sport: string) => addWidgetsV2Script(sport, widgetVersion));
};

export const generateWidgetsV2LoadScript = (sport: string) => {
	const sportCapitalized = capitalize(sport);
	const sportLower = sport.toLocaleLowerCase();

	// priority is the feature data field, then the project data field
	const { competitionList, oddClient, authentication } = featuresService.checkFeatureDataField(convertSportToFeatureType(sportLower));
	const { domain, language, defaultTournamentOrder, timezone, defaultOddClient } = store.getState().project.currentProject;
	let customWidgetAttributes = {};
	if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WIDGET_V2_CUSTOM_ATTRIBUTES)) {
		customWidgetAttributes = featuresService.checkFeatureDataField(FeatureTypes.WIDGET_V2_CUSTOM_ATTRIBUTES) || {};
	}
	const subdomain = getProjectSubdomain();

	return `<script> window.smp${sportCapitalized}Widgets.LoadSmpWidget({
            sdkOptions: {
                dataConfigApiUrl: "https://${sportLower}.api${subdomain}.sportal365.com",
				dataConfigStandingsApiUrl: "https://standing.api${subdomain}.sportal365.com",
				dataConfigPlayoffApiUrl: "https://playoffs.api${subdomain}.sportal365.com/playoffs",
                dataConfigApiKey: "${authentication.username + ':' + authentication.password}",
                dataConfigProject: "${domain}",
                dataConfigOddClient: "${oddClient || defaultOddClient || 'sportal'}",
                dataConfigTimezone: "${timezone || 'Europe/Sofia'}",
                dataConfigCompetitionList: "${competitionList || defaultTournamentOrder || 'all'}",
                dataConfigLang: "${language || 'en'}",
            },
            widgetAttributes: ${JSON.stringify(customWidgetAttributes)}
        })</script>`;
};

export const loadWidgetV2Html = (widgetHtml: string, sport: string, element: HTMLElement) => {
	if (element) {
		const scriptText = generateWidgetsV2LoadScript(sport);
		const widgetWithoutRefreshTime = replaceRefreshTimeWithNever(widgetHtml);
		loadHTMLText(widgetWithoutRefreshTime + scriptText, element);
	}
};

const ensureRefreshTimeAttribute = (htmlString: string) => {
	if (!htmlString.includes(' data-refresh-time=')) {
		const index = htmlString.lastIndexOf('></div>');
		if (index !== -1) {
			return htmlString.slice(0, index) + ` data-refresh-time='${refreshTimeDisableValue}'` + htmlString.slice(index);
		}
	}
	// Return the original string if the attribute is already included
	return htmlString;
};

const replaceRefreshTimeWithNever = (widgetHtml: string) => {
	let result = widgetHtml;
	WidgetV2RefreshTimes.forEach((refreshTime) => {
		result = result
			.replaceAll(`data-refresh-time='${refreshTime}'`, `data-refresh-time='${refreshTimeDisableValue}'`)
			.replaceAll(`data-refresh-time="${refreshTime}"`, `data-refresh-time='${refreshTimeDisableValue}'`);
	});

	return ensureRefreshTimeAttribute(result);
};
